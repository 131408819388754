/* TODO Add site wide styles */
body {
    margin: 0;
    padding: 0;
    border: none;
}

.main__content {
    padding-top: 120px;
}

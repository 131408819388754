.profile-dropdown {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 16px rgba(0,0,0,0.12);
  list-style-type: none;
  padding: 0 20px;
  position: absolute;
  right: 80px;
}

.profile-dropdown button {
  cursor: pointer;
  padding: 5px 10px;
}

.hidden {
  display: none;
}

header {
  align-items: center;
  background-color: white;
  border-bottom: 2px solid #ddd;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  padding: 30px 80px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.header__logo {
  font-size: 30px;
  font-weight: bold;
  display: flex;
  grid-column: 2 / span 1;
  justify-content: center;
}

.header__logo a {
  color: black;
  text-decoration: none;
}

.header__profile {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.header__profile-link {
  color: black;
  text-decoration: none;
}

.header__profile button {
  border-radius: 5px;
}

.header__login,
.header__logout {
  background-color: #72bbb0;
  border: 1px solid #72bbb0;
  color: white
}

.header__login {
  margin: 5px;
}

.header__signup {
  background-color: white;
  border: 2px solid #72bbb0;
  color: #72bbb0;
  margin: 5px;
}

.header__logout {
  margin: 5px 0;
}

.header__avatar {
  background-color: transparent;
  border: none;
  color: gray;
  cursor: pointer;
}

.header__avatar i {
  transform: scale(3);
}

.header__post-album {
  background-color: #38736d;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  margin-right: 50px;
  padding: 5px 10px;
  text-decoration: none;
}

.header__post-album-plus {
  display: none;
  background-color: #38736d;
  border-radius: 50px;
  color: white;
  font-size: 25px;
  margin-right: 50px;
  padding: 0 10px;
  text-decoration: none;
}

@media (max-width: 750px) {
  header {
    padding: 30px 30px 20px;
  }
  .profile-dropdown {
    right: 20px;
  }
}

@media (max-width: 667px) {
  .header__post-album-plus {
    display: unset;
  }
  .header__post-album {
    display: none;
  }
}

@media (max-width: 415px) {
  header {
    display: flex;
  }
}

.delete-review-container {
    height: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
}

.delete-review-buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-bottom: 15px;
}

.delete-review-buttons-delete {
    padding: 4px 7px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFFFF;
    background: rgb(242, 3, 3);
    opacity: 1;
    border: none;
    border-radius: 4px;
    margin-right: 5px;
}

#delete-review-confirm-delete {
    font-size: 25px;
    padding-top: 15px;
}

.clickable:hover {
    cursor: pointer;
}

@media (max-width: 480px) {
    .delete-review-container {
        width: 70vw;
        height: 50vmin;
    }

    #delete-review-confirm-delete {
        padding-top: 2px;
    }

    p {
        margin-top: 2px;
    }

    .delete-review-buttons-container {
        height: 10%;
        border-radius: 4px;
        overflow: visible;
    }

    .delete-review-buttons-delete {
        height: 235%;
        font-size: 12px;
        padding: 5px 4px;
        line-height: 14px;
        overflow: visible;
    }

    .delete-review-buttons {
        height: 235%;
        font-size: 12px;
        padding: 5px 4px;
        line-height: 14px;
        overflow: visible;
    }
}

@media (max-width: 319px) {
    .delete-review-buttons-delete {
        height: 300%;
        font-size: 12px;
        padding: 5px 4px;
        line-height: 14px;
        overflow: visible;
    }

    .delete-review-buttons {
        height: 300%;
        font-size: 12px;
        padding: 5px 4px;
        line-height: 14px;
        overflow: visible;
    }
}

#team {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
    position: relative;
    z-index: 0;
}


.team__member {
    cursor: pointer;
    padding: 50px;
    position: relative;
}


.animated {
    animation: rotateOnce 0.5s forwards;
}


@keyframes rotateOnce {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }

.team__member a {
    text-decoration: none;
}

.team__avatar {
    border-radius: 100px;
    display: block;
    width: 200px;
}

.team__text {
    align-items: center;
    display: flex;
    justify-content: center;
}

.team__text svg {
    color: black;
    transform: scale(1.5);
}

.team__link {
    color: #38736d;
    margin-left: 10px;
    /* text-align: center; */
}

#review-text-input {
    width: 90%;
    height: 100px;
    margin-bottom: 10px;
    font-family: "Manrope";
}

#submit-review-button {
    width: 85%;
    margin-bottom: 18px;
    opacity: 1;
    border: none;
    border-radius: 4px;
    padding: 8px;
}

#submit-review-button.true {
    background-color: darkgrey;
    color: white;
}

#submit-review-button.false {
    background-color: #71BBB2FF;
    color: white;
}

.star-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 10px;
}

.star {
    font-size: 20px;
    cursor: pointer;
    margin-right: 5px;
    background-color: white;
}

.star:hover,
.star.active {
    color: gold;
}

#label-for-stars {
    margin-left: 10px;
}

h1 {
    padding-bottom: 10px;
}

.clickable:hover {
    cursor: pointer;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Manrope";
}

h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    color: #171A1FFF;
    padding-left: 5px;
    padding-right: 5px;
}

#review-text-input-label {
    align-self: flex-start;
    margin-bottom: 5px;
    margin-left: 5%;
}

.review-form-modal {
    border: 4px solid #BDC1CAFF;
    border-radius: 6px;
    box-shadow: 0px 0px 1px;
    padding: 25px;
}


#review-text-input {
    border-radius: 4px;
    border: 1px solid #1E2128FF;
}


@media (max-width: 750px) {
    .review-form-modal {
        width: 70vw;
    }
}

@media (max-width: 415px) {
    #review-form-header {
        font-size: 25px;
    }
}

@media (max-width: 320px) {
    #review-form-header {
        font-size: 20px;
        text-align: center;
    }
}

* {
    font-family: manrope, sans-serif;
}

.display-reviews {
    padding-top: 10px;
    padding-left: 30px;
}
.top-half {
    margin: auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
    align-items: center;
}

.left-center {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.review-header{
    color: gray;
    padding-top: 50px;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    justify-content: space-between;
}

.review-bar {
    padding-right: 10px;
    padding-left: 30px;
}

.left {
    flex-basis: 10px;
    /* align-self: start; */
}

.heart-container {
    margin-right: 5px;
    width: 30px;
    height: 30px;
}

.heart-click {
    cursor: pointer;
}

.heart, .empty-heart {
    size: 100%;
}

.heart {
    color: #DE3B40FF;
}

.empty-heart {
    color: black;
}

.image {
    height: 300px;
    width: 300px;
}

.likes {
    display: flex;
    font-size: 175%;
    font-weight: 400;
    justify-content: center;
}

.likes span {
    font-size: 20px;
}

.center {
    justify-self: start;
    /* padding-left: 15px; */
}

.right {
    align-self: center;
    padding-right: 10px;
    justify-items: center;
}

.left {
    align-self: center;
}

.rating-container {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 3px solid black;
    box-sizing: border-box;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin-bottom: 30px;
}

.rating {
    font-size: 15pt;
}

.h2 {
    font-size: 24pt;
    font-weight: bold;
    font-style: italic;
    padding: 5px;
}

.h3 {
    font-size: 18pt;
    font-weight: lighter;
    font-style: italic;
    text-align: left;
}

.page {
    margin: 0 15px;
}

.post-review-button {
    color: white;
    background: #38736d;
    opacity: 1;
    border: none;
    border-radius: 4px;
    width: 300px;
    height: 40px;
    gap: 6px;
}

.likeBtn {
    color: white;
    cursor: pointer;
    background: #38736d;
    opacity: 1;
    border: none;
    border-radius: 4px;
    width: 50%;
    height: 20px;
    gap: 6px;
}

.disabled {
    display: none;
}


@media screen and (max-width:990px) {

    .page {
        margin: auto;
        padding-right: 5px;
    }

    .likes {
        display: flex;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 25px;
    }

    .top-half {
        display: flex;
        justify-content: space-around;
        align-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 100%;
    }

    .left-center {
        display: flex;
        flex-direction: column;
        gap: unset;
    }

    .center {
        align-self: center;
        padding-bottom: 10px;
    }
    .right {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 30px;
    }

    .title, .artist, .release_date, .genre, .description {
        text-align: center;
    }

    .rating-container {
        display: flex;
        justify-content: center;
        align-items: center;
        outline: 3px solid black;
        box-sizing: border-box;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        margin-bottom: 30px;
    }

    .rating {
        font-size: 11pt;
    }

}

@media screen and (max-width:415px) {
    .left-center, .left, .image, .post-review-button {
        width: 100%;
    }

    .image {
        height: unset;
    }

}

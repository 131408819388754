.album-form {
    margin: auto;
    width: 95%;
}

.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas:
    "image image title title"
    "image image genre genre"
    "image image date date"
    "url url description description"
    "btn btn description description"
    ". . description description";
    column-gap: 20px;
    row-gap: 15px;
}

.title {
    grid-area: title;
}

#title {
    height:40px;
    width:400px;
}

::title {
    color: lightgrey;
    opacity: 1;
}

.genre {
    grid-area: genre;

}

#genre {
    height:40px;
    width:400px;
}

::genre {
    color: lightgrey;
    opacity: 1;
}

.description {
    grid-area: description;

}

#description {
    height:150px;
    width:400px;
}

::description {
    color: lightgrey;
    opacity: 1;
}

.date {
    grid-area: date;
}

#release_date{
    height:40px;
    width:400px;
}

input[type=date]{
    color: gray;
    opacity: 1;
}

.image {
    grid-area: image;
}

#image {
    height: 300px;
    width: 400px;
}

.url {
    grid-area: url;

}

#image_url {
    height:40px;
    width:400px;
}

::image_url {
    color: lightgrey;
    opacity: 1;
}

.btn {
    grid-area: btn;
}

.submit-btn {
    height:50px;
    width:400px;
    color: white;
    background: #71BBB2FF;
    opacity: 1;
    border: none;
    border-radius: 4px;
}

@media screen and (max-width:900px) {

    .container {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        gap: 10px;
    }


    #title {
        height:40px;
        width:100%;
        box-sizing: border-box;

    }

    #genre {
        height:40px;
        width:100%;
        box-sizing: border-box;

    }

    #description {
        height:150px;
        width:100%;
        box-sizing: border-box;

    }

    #release_date{
        height:40px;
        width:100%;
        box-sizing: border-box;

    }

    #image {
        height: auto;
        max-width:100%;
        box-sizing: border-box;

    }

    #image_url {
        height:40px;
        width:100%;
        box-sizing: border-box;
    }

    .submit-btn {
        height:50px;
        width:100%;
        color: white;
        background: #71BBB2FF;
        opacity: 1;
        border: none;
        border-radius: 4px;
    }
}
